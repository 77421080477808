var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"pa-0 d-flex justify-center alig-center overflow-scroll h-100",style:({
        backgroundImage: `url(https://d5seczr4segy5.cloudfront.net/context_images/${_vm.contextual_image})`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        minHeight: `100vh`,
    }),attrs:{"id":`book-container`}},[_c('vue-headful',{attrs:{"title":_vm.$t('title_bookReading'),"description":"title"}}),_c('v-row',{class:`${
            _vm.currentStage === 'bookCover' ? 'd-flex' : 'd-none'
        } ma-7 justify-center flex-column`},[_c('BookInitCard',{attrs:{"book":_vm.book,"avgRating":_vm.avgRating,"userRating":_vm.userRating}})],1),_c('v-row',{class:`${
            _vm.currentStage === 'bookReading' ? 'd-flex' : 'd-none'
        } ma-7 justify-center flex-column`},[(_vm.book)?_c('MainContainer'):_vm._e()],1),_c('v-row',{class:`${
            _vm.currentStage === 'bookEnd' ? 'd-flex' : 'd-none'
        } ma-7 justify-center flex-column`},[_c('BookFinishCard',{attrs:{"book":_vm.book}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }