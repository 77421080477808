<template>
    <div class="position-relative celebration">
        <UtilsContent @close-action="closeQuestion">
            <template v-slot:header>
                <div class="d-flex flex-row justify-start align-center mx-3 mb-4">
                    <img :src="questionsImg" class="img-index mr-2" alt="" />
                    <span
                        class="text-h5 ma-0 text--primary"
                    >
                        {{ $t('bookReading.question') }}
                    </span>
                    <span
                        class="text-body-2 ml-2 mt-1 text--primary"
                    >
                        {{ questionVariant }}
                    </span>
                </div>
            </template>
            <template v-slot:content>
                <div
                    class="d-flex flex-column questions-container"
                    id="questions-container"
                    v-if="questionsForChapter.length"
                >
                    <QuestionItem
                        :data="currentQuestion"
                        :item_type="itemType"
                        :correct="selectedCorrect"
                        :cleanSelection="cleanCounter"
                    />
                </div>
                <div class="d-flex flex-column questions-container" v-else>
                    <span class="text-h6 text--primary">
                        {{ $t('bookReading.no_questions') }}
                    </span>
                </div>
            </template>
            <template v-slot:actions v-if="questionsForChapter.length">
                <v-btn
                    color="secondary"
                    rounded
                    class="mt-5 py-5 px-10 text--white"
                    @click="evaluateQuestion"
                >
                    <span dark class="text--white text-capitalize">
                        {{ $t('bookReading.continue') }}
                    </span>
                </v-btn>
            </template>
        </UtilsContent>
        <app-footer ref="appFooter" class="d-none" />
    </div>
</template>
<script>
/* eslint-disable global-require */
import { mapState, mapActions } from 'vuex'
import global from '@/constants/GlobalConstant'

import UtilsContent from '@/components/library/preview/core/UtilsContent.vue'
import QuestionItem from './items/QuestionItem.vue'

export default {
    components: {
        UtilsContent,
        QuestionItem,
    },
    computed: {
        ...mapState({
            indexItem: (state) => state.bookReading.indexItem,
            itemType: (state) => state.bookReading.itemType,
            currentQuestion: (state) => state.bookReading.currentQuestion,
            questionsForChapter: (state) => state.bookReading.questionsForChapter,
            indexQuestion: (state) => state.bookReading.indexQuestion,
            itemsData: (state) => state.bookReading.itemsData,
            streak: (state) => state.bookReading.streak,
            coins: (state) => state.userData.coins,
            points: (state) => state.userData.points,
            user: (state) => state.userData.user,
            lastQuestionOfGroup: (state) => state.bookReading.lastQuestionOfGroup,
            lastQuestionOfBook: (state) => state.bookReading.lastQuestionOfBook,
            bookAssessmentData: (state) => state.bookReading.bookAssessmentData,
        }),
        questionVariant() {
            if (!this.currentQuestion) {
                return ''
            }
            return this.currentQuestion.itemVariant === 'A'
                ? `(${this.$t('bookReading.first_chance')})`
                : `(${this.$t('bookReading.second_chance')})`
        },
    },
    data() {
        return {
            questionsImg: require('@/assets/img/icons/reading/3-questions.svg'),
            showOptions: false,
            secondChance: false,
            global,
            selectedCorrect: 0,
            cleanCounter: 0,
        }
    },
    mounted() {
        this.showCorrectAnswer()
    },
    methods: {
        ...mapActions('bookReading', [
            'setCurrentQuestion',
            'setQuestionForChapter',
            'setIndexItem',
            'setItemType',
            'hideAllUtils',
            'setHasQuestion',
            'setLastQuestionOfGroup',
            'setLastQuestionOfBook',
        ]),
        closeQuestion() {
            this.$emit('close-question')
        },
        evaluateQuestion() {
            if (this.secondChance) {
                this.nextLot()
                return
            }
            this.secondChance = true
            this.tryAgain()
            this.showCorrectAnswer()
        },
        nextLot() {
            this.cleanOptions()
            this.setIndexItem(this.indexItem + 1)
            if (this.indexItem <= this.itemsData.length - 1) {
                this.cleanCorrectAnswer()
                this.setQuestionForChapter(this.itemsData[this.indexItem].questions)
                this.setCurrentQuestion(this.questionsForChapter[0])
                this.setItemType(this.itemsData[this.indexItem].itemType)
                const index = this.bookAssessmentData.indexOf(this.itemsData[this.indexItem])
                this.setLastQuestionOfGroup(this.indexItem === this.itemsData.length - 1)
                this.setLastQuestionOfBook(index === this.bookAssessmentData.length - 1)
                this.secondChance = false
                const indexToDelete = this.bookAssessmentData.indexOf(
                    this.itemsData[this.indexItem - 1],
                )
                this.bookAssessmentData.splice(indexToDelete, 1)
            } else {
                this.setQuestionForChapter([])
                this.setCurrentQuestion(null)
                this.setItemType(null)
                this.secondChance = false
                this.setHasQuestion(false)
                this.hideAllUtils()
                this.setIndexItem(0)
                this.$refs.appFooter.next()
            }
            this.showCorrectAnswer()
        },
        tryAgain() {
            this.cleanOptions()
            this.setCurrentQuestion(this.questionsForChapter[1])
        },
        cleanOptions() {
            this.selectedCorrect = 0
            this.cleanCounter += 1
            document.querySelectorAll('.hide-option').forEach((el) => {
                el.classList.toggle('hide-option')
            })
        },
        showCorrectAnswer() {
            const correctAnswer = this.currentQuestion
                ? this.currentQuestion.answers.find((answer) => answer.itemNumber === 4)
                : null
            if (correctAnswer) {
                const { id } = correctAnswer
                this.selectedCorrect = id
            }
            if (!correctAnswer
                && this.currentQuestion
                && this.currentQuestion.answers.length === 0
            ) {
                this.selectedCorrect = this.currentQuestion.answerTrueOrFalse ? '1' : '0'
            }
        },
        cleanCorrectAnswer() {
            const el = document.querySelector('.correct')
            if (el) {
                el.classList.toggle('correct')
                el.classList.toggle('white--text')
                el.classList.toggle('primary--text')
            }
        },
    },
}
</script>
<style>
.questions-container {
    max-height: 54vh;
    overflow: auto;
}

.cls-button {
    z-index: 11;
    bottom: 17.5vh;
    left: 1vh;
}

.hide-option {
    display: none !important;
    clear: both;
}
</style>
