<template>
    <div>
        <UtilsContent @close-action="closeIndex">
            <template v-slot:header>
                <div class="d-flex flex-row justify-start align-center mx-3 mb-4">
                    <img :src="indexImg" class="img-index mr-2" alt="" /><span
                        class="text-h5 ma-0 text--primary"
                        >{{ $t('bookReading.index') }}</span
                    >
                </div>
            </template>
            <template v-slot:content>
                <div class="d-flex flex-column chapters-container" id="chapter-container">
                    <IndexItem
                        v-for="(chapter, index) of chapters"
                        :key="index"
                        :chapter_data="chapter"
                        :active="chapter.chapterNumber === currentChapter"
                    />
                </div>
            </template>
            <template v-slot:actions>
                <v-btn color="#95afad" fab small dark class="mt-5 text--white" @click="downScroll"
                    ><v-icon dark class="text--white">mdi-arrow-down</v-icon></v-btn
                >
            </template>
        </UtilsContent>
    </div>
</template>
<script>
/* eslint-disable global-require */
import { mapState } from 'vuex'

import UtilsContent from '@/components/library/preview/core/UtilsContent.vue'
import IndexItem from './items/IndexItem.vue'

export default {
    components: {
        UtilsContent,
        IndexItem,
    },
    computed: {
        ...mapState({
            chapters: (state) => state.bookReading.book.bookChapterDTOList,
            currentChapter: (state) => state.bookReading.currentChapter,
        }),
    },
    data() {
        return {
            indexImg: require('@/assets/img/icons/reading/1-index.svg'),
            scrollPos: 0,
        }
    },
    methods: {
        downScroll() {
            const el = document.getElementById('chapter-container')
            el.scrollTop += 10
        },
        closeIndex() {
            this.$emit('close-index')
        },
    },
}
</script>
<style>
.chapters-container {
    height: 35vh;
    max-height: 35vh;
    overflow: auto;
}
</style>
