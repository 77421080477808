<template>
    <v-row
        class="pa-0 d-flex justify-center alig-center overflow-scroll h-100"
        :style="{
            backgroundImage: `url(https://d5seczr4segy5.cloudfront.net/context_images/${contextual_image})`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            minHeight: `100vh`,
        }"
        :id="`book-container`"
    >
        <vue-headful :title="$t('title_bookReading')" description="title" />
        <v-row
            :class="`${
                currentStage === 'bookCover' ? 'd-flex' : 'd-none'
            } ma-7 justify-center flex-column`"
        >
            <BookInitCard
                :book="book"
                :avgRating="avgRating"
                :userRating="userRating"
            ></BookInitCard>
        </v-row>
        <v-row
            :class="`${
                currentStage === 'bookReading' ? 'd-flex' : 'd-none'
            } ma-7 justify-center flex-column`"
        >
            <MainContainer v-if="book" />
        </v-row>
        <v-row
            :class="`${
                currentStage === 'bookEnd' ? 'd-flex' : 'd-none'
            } ma-7 justify-center flex-column`"
        >
            <BookFinishCard :book="book" />
        </v-row>
    </v-row>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import BookService from '@/services/book'
import BookRatingService from '@/services/bookRating'

import BookInitCard from '@/components/library/preview/BookInitCard.vue'
import BookFinishCard from '@/components/library/preview/BookFinishCard.vue'
import MainContainer from '@/components/library/preview/content/MainContainer.vue'

export default {
    props: {
        bookId: { type: [String, Number], default: '' },
    },
    computed: {
        ...mapState({
            user: (state) => state.userData.user,
            book: (state) => state.bookReading.book,
            currentStage: (state) => state.bookReading.currentStage,
            bookCategories: (state) => state.bookReading.bookCategories,
            epubPagesLoaded: (state) => state.bookReading.epubPagesLoaded,
            pagesLoaded: (state) => state.bookReading.pagesLoaded,
        }),
        contextual_image() {
            const userBirthdate = this.$moment(this.user.birthDate, 'YYYY')
            const diff = this.$moment().diff(userBirthdate, 'years')
            if (this.book && this.bookCategories.length) {
                const category = this.bookCategories[
                    Math.floor(Math.random() * this.bookCategories.length)
                ]
                if (category && category.categoryContextualImageDTOList) {
                    const image = category.categoryContextualImageDTOList.find(
                        (el) => diff >= el.bookCategorizationAgeRangeDTO.fromAge
                            && diff <= el.bookCategorizationAgeRangeDTO.toAge
                    )

                    if (image) {
                        return `${image.resourceDir}.png`
                    }
                }
            }
            return 'default.jpg'
        },
    },
    components: {
        BookInitCard,
        MainContainer,
        BookFinishCard,
    },
    beforeDestroy() {
        this.resetBookReadingState()
    },
    async mounted() {
        const bookRatingData = await BookRatingService.getRatingsByBook({ bookId: this.bookId })
        this.setBookRatingData(bookRatingData.content.content)
        this.setTotalRating(bookRatingData.content.totalElements)
        await BookService.getBookSummaryInfo(this.bookId, this.user.id)
            .then((response) => {
                if (response.status.name.toLocaleUpperCase() === 'ERROR') {
                    throw new Error(this.$t('general.error_msg'))
                }
                const {
                    bookDTO,
                    bookAssessmentItemDTOList,
                    bookUserReadConfDTO,
                    metaDataCategoryDTOList,
                    avgRating,
                    bookRatingDTO,
                } = response.content
                this.setBook(bookDTO)
                this.setBookAssessmentData(bookAssessmentItemDTOList)
                this.setBookReadConf(bookUserReadConfDTO)
                this.setBookCategories(metaDataCategoryDTOList)
                this.avgRating = avgRating
                this.userRating = bookRatingDTO
            })
            .catch((error) => {
                console.error(error)
                this.$modal.loadError()
            })
        if (this.book.formatType === 'EPUB') {
            this.getEpubContent()
        }
    },
    methods: {
        ...mapActions('bookReading', [
            'setBook',
            'setBookAssessmentData',
            'setBookReadConf',
            'setBookCategories',
            'resetBookReadingState',
            'setEpubPages',
            'setBookRatingData',
            'setTotalRating',
        ]),
        async getEpubContent() {
            const vm = this
            await BookService.getEPUBBookContent(this.book.resourceDir)
                .then(async (response) => {
                    const content = await response.text()

                    const div = document.createElement('div')
                    div.innerHTML = content
                    const manifest = div.querySelector('manifest')
                    const spine = div.querySelector('spine')

                    spine.querySelectorAll('itemref').forEach((value, index) => {
                        const idref = value.getAttribute('idref')
                        console.debug('idref: ', idref)

                        const page = {
                            number: index + 1,
                            idref,
                            href: null,
                        }
                        vm.epubPages.push(page)
                    })

                    vm.epubPages.forEach((page) => {
                        const { idref } = page
                        page.href = manifest
                            .querySelector(`item[id="${idref}"]`)
                            .getAttribute('href')
                    })

                    vm.setEpubPages(vm.epubPages)
                })
                .catch((error) => console.error(error))
        },
    },
    data() {
        return {
            avgRating: null,
            userRating: null,
            epubPages: [],
        }
    },
}
</script>
