<template>
    <div
        :class="'w-90 pa-5 d-flex flex-column justify-center align-center pa-3 mx-3 border-radius-0 mb-2 item-comment-content border-radius-2'"
    >
        <v-container>
            <v-row class="d-flex flex-row justify-start">
                <v-col cols="1" class="mr-2 d-flex justify-center pa-0">
                    <v-sheet
                        :class="'rounded-circle'"
                        :width="50"
                        class="mx-auto transition-swing d-flex justify-center align-center"
                        :color="classColor"
                        elevation="5"
                        height="50"
                        v-if="!comment_data.bookUser.avatar"
                    >
                        <span class="white--text">{{ IconName }}</span>
                    </v-sheet>
                    <img
                        :src="`https://d5seczr4segy5.cloudfront.net/avatar/${comment_data.bookUser.avatar.name}/${comment_data.bookUser.avatar.value}`"
                        alt="userAvatar"
                        height="70"
                        :width="70"
                        :class="'rounded-circle mx-auto transition-swing'"
                        v-else
                    />
                </v-col>
                <v-col cols="10">
                    <div
                        class="w-90 d-flex flex-row justify-space-between align-start brown--text darken-3"
                    >
                        <span class="text-subtitle-2 font-weight-black text-capitalize">{{
                            commentName
                        }}</span>
                        <span class="text-body-2 text-left text-cursive">{{ commentDate }}</span>
                    </div>
                    <!-- <div
                        class="w-90 d-flex flex-row justify-start align-start mt-2 brown--text darken-3"
                    >
                        <span class="text-body-2 text-left">{{ comment_data.description }}</span>
                    </div> -->
                    <div class="w-90 d-flex flex-row justify-start align-start" v-if="rating">
                        <star-rating
                            :star-size="22"
                            :show-rating="false"
                            v-model="rating"
                            read-only
                        ></star-rating>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        rating: { type: Number, default: null },
        comment_data: { type: Object, default: null },
    },
    computed: {
        commentName() {
            return `${this.comment_data.bookUser.firstname} ${this.comment_data.bookUser.lastname}`
        },
        commentDate() {
            return this.$moment(this.comment_data.ratingDate).format(`DD MMMM YYYY`)
        },
        IconName() {
            return `${this.comment_data.bookUser.firstname
                .split(' ')[0]
                .split('')[0]
                .toUpperCase()}${this.comment_data.bookUser.lastname
                .split(' ')[0]
                .split('')[0]
                .toUpperCase()}`
        },
    },
    data() {
        return {
            colors: [
                '#E53935',
                '#D50000',
                '#4A148C',
                '#AA00FF',
                '#3F51B5',
                '#64B5F6',
                '#00796B',
                '#01579B',
            ],
            classColor: '',
        }
    },
    mounted() {
        this.classColor = this.colors[Math.floor(this.colors.length * Math.random())]
    },
}
</script>
<style>
.item-comment-content {
    background: #e4f3f7 !important;
}
</style>
