<template>
    <div :id="'chapter_' + chapter.chapterNumber" class="chapter" style="display: none;">
        <div
            class="content flex-column justify-center"
            v-html="htmlContent"
        ></div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import { mapState, mapActions } from 'vuex'
import * as Sentry from '@sentry/vue'
import BookService from '@/services/book'

export default {
    data() {
        return {
            selectedtext: '',
            htmlContent: '',
        }
    },
    props: {
        resource: {
            type: String,
            defaul: '',
        },
        chapter: {
            type: Object,
            defaul: {},
        },
    },
    computed: {
        ...mapState({
            bookReadConf: (state) => state.bookReading.bookReadConf,
        }),
    },
    async mounted() {
        const vm = this
        const url = `${process.env.VUE_APP_BOOK_DATA_URL}${this.resource}/c_${this.$utils.pad(
            this.chapter.chapterNumber,
            2,
        )}.html`
        await BookService.getChapterContentHTML(url)
            .then((response) => {
                response.text().then((d) => {
                    vm.htmlContent = d
                })
            })
            .catch((error) => Sentry.captureException(error))
        this.increasePagesLoaded()
    },
    methods: {
        ...mapActions('bookReading', ['nextPage', 'backPage', 'newMarker', 'search', 'increasePagesLoaded']),
    },
}
</script>
