<template>
    <div>
        <UtilsContent @close-action="closeSettings">
            <template v-slot:header>
                <div class="d-flex flex-row justify-start align-center mx-3 mb-4">
                    <img :src="settingImg" class="img-index mr-2" alt="" /><span
                        class="text-h5 ma-0 text--primary"
                        >{{ $t('bookReading.settings') }}</span
                    >
                </div>
            </template>
            <template v-slot:content>
                <div class="d-flex flex-column mx-3">
                    <v-row justify="start" align="start">
                        <v-col cols="12" class="d-flex flex-column justify-start align-start">
                            <span class="text--primary font-size-12">Tamaño del texto:</span>
                            <div class="d-flex flex-row justify-start align-start mt-3">
                                <FontSizeSelector
                                    v-for="(size, index) of fontSizes"
                                    :key="index"
                                    :font_size="size"
                                    :active="bookReadConf.fontSize === size"
                                >
                                    <span class="font-weight-light">{{ size }}p</span>
                                </FontSizeSelector>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex flex-column justify-start align-start">
                            <span class="text--primary font-size-12">Tipo de letra:</span>
                            <div class="d-flex flex-row justify-start align-start mt-3">
                                <FontFamilySelector
                                    v-for="(family, index) of fontFamilies"
                                    :key="index"
                                    :font_family="family"
                                    :active="bookReadConf.fontFamily === family.fontFamilyId"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </UtilsContent>
    </div>
</template>
<script>
/* eslint-disable global-require */
import { mapState } from 'vuex'

import UtilsContent from '@/components/library/preview/core/UtilsContent.vue'
import FontSizeSelector from './items/FontSizeSelector.vue'
import FontFamilySelector from './items/FontFamilySelector.vue'

export default {
    components: {
        UtilsContent,
        FontSizeSelector,
        FontFamilySelector,
    },
    computed: {
        ...mapState({
            bookReadConf: (state) => state.bookReading.bookReadConf,
        }),
    },
    data() {
        return {
            settingImg: require('@/assets/img/icons/reading/2-settings.svg'),
            fontSizes: ['16', '18', '22', '24', '28'],
            fontFamilies: [
                {
                    fontFamilyId: '0',
                    fontFamilyName: 'Times',
                    fontFamilyValue: '"Times New Roman", Times, serif',
                },
                {
                    fontFamilyId: '1',
                    fontFamilyName: 'Roboto',
                    fontFamilyValue: "'Roboto', sans-serif",
                },
                {
                    fontFamilyId: '2',
                    fontFamilyName: 'Comic',
                    fontFamilyValue: "'Comic Sans MS', arial",
                },
                {
                    fontFamilyId: '3',
                    fontFamilyName: 'Fantasy',
                    fontFamilyValue: "'Arial', fantasy",
                },
            ],
        }
    },
    methods: {
        closeSettings() {
            this.$emit('close-settings')
        },
    },
}
</script>
<style>
.chapters-container {
    height: 35vh;
    max-height: 35vh;
    overflow: auto;
}
</style>
