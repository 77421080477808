import Services from './base-services'

const service = new Services('bookRating')

export default {
    getBookRatingForUser: (data: any) => {
        return service.post(`getBookRatingForUser`, data)
    },
    getBookRating: (bookId: number) => {
        return service.post(`getBookRating?bookId=${bookId}`)
    },
    addBookRating: (data: any) => {
        return service.post('addBookRating', data)
    },
    updateBookRating: (data: any) => {
        return service.put('updateBookRating', data)
    },
    updateOrCreateBookRating: (data: any) => {
        return service.post('updateOrCreateBookRating', data)
    },
    getRatingsByBook: (data: any) => {
        return service.get(
            `getRatingsByBook?bookId=${data.bookId}&size=${data.size || 10}&number=${
                data.number || 0
            }`
        )
    },
}
