<template>
    <div class="position-relative util-content pa-8">
        <slot name="header"></slot>
        <slot name="content"></slot>
        <slot name="actions"></slot>
        <div class="position-absolute btn-close">
            <v-btn icon color="primary" @click="click">
                <v-icon class="font-size-32 white rounded-circle"> mdi-close-circle</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        click() {
            this.$emit('close-action')
        },
    },
}
</script>
<style>
.util-content {
    width: 35vw;
    background: #e9f5f4;
    border-radius: 1vh 0vh 1vh 1vh;
    border: 1.5px solid #4bada1;
    margin-right: -1em;
    z-index: 4;
}

.btn-close {
    top: -1rem;
    left: -1rem;
}
</style>
