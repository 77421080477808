<template>
    <div class="d-flex flex-column justify-center align-center">
        <v-row align="center" justify="center" class="px-5 mb-2 mt-2 w-100">
            <span
                class="text-body-1 primary--text text-justify font-weight-bold px-3 w-100"
                v-if="data && data.content"
            >
                {{ data.content }}
            </span>
            <v-row
                class="w-100"
                align="center"
                justify="center"
                v-if="item_type === 'CLOSED_4OPTIONS'"
            >
                <v-col
                    cols="12"
                    v-for="answer of data.answers"
                    :key="`radio-${data.id}-${answer.id}`"
                    :id="answer.id"
                    v-ripple
                    :class="classSelected(answer.id)"
                    v-show="answer.itemNumber"
                >
                    {{ $utils.upperFirstLetter(answer.content) }}
                </v-col>
            </v-row>
            <v-row
                class="w-100"
                align="center"
                justify="center"
                v-if="item_type === 'CLOSED_TRUE_FALSE'"
            >
                <v-col
                    cols="12"
                    v-ripple
                    :class="classSelectedTrueOrFalse('1')"
                    :key="'radio-' + data.id + '-true'"
                >
                    {{ $t('true') }}
                </v-col>
                <v-col
                    cols="12"
                    v-ripple
                    :class="classSelectedTrueOrFalse('0')"
                    :key="'radio-' + data.id + '-false'"
                >
                    {{ $t('false') }}
                </v-col>
            </v-row>
        </v-row>
    </div>
</template>
<script>
export default {
    props: {
        data: { type: Object, default: null },
        item_type: { type: [Number, String], default: '' },
        correct: { type: [Number, String], default: 0 },
        cleanSelection: { type: Number, default: 0 },
    },
    data() {
        return {
            btnSelected: {
                id: null,
                value: null,
                secret: null,
            },
        }
    },
    mounted() {
        if (this.item_type === 'CLOSED_4OPTIONS') {
            this.btnSelected.id = this.correct
            this.btnSelected.value = this.correct
        } else {
            this.btnSelected.id = this.correct
        }
    },
    methods: {
        classSelected(answerValue) {
            let colorText = 'primary--text'
            if (answerValue === this.correct) {
                colorText = 'white--text'
            }
            return `w-100 my-3 transition-all cursor-pointer pa-2 border-radius-1 text-none option-btn ${colorText} ${this.bgColor(
                answerValue,
            )}`
        },
        classSelectedTrueOrFalse(answerValue) {
            let colorText = 'primary--text'
            if (answerValue === this.correct) {
                colorText = 'white--text'
            }
            return `w-100 my-3 transition-all cursor-pointer pa-2 border-radius-1 text-none option-btn ${colorText} ${this.bgColorTrueOrFalse(
                answerValue,
            )} `
        },
        bgColor(answerValue) {
            let result = 'white'
            if (answerValue === this.correct) {
                result = 'correct'
            }
            return result
        },
        bgColorTrueOrFalse(answerValue) {
            let result = 'white'
            if (answerValue === this.correct) {
                result = 'correct'
            }
            return result
        },
    },
    watch: {
        cleanSelection() {
            this.btnSelected = {
                id: null,
                value: null,
                secret: null,
            }
        },
    },
}
</script>

<style scoped>
.icon {
    border-radius: 500px;
}

.icon i {
    font-size: 24px;
    font-weight: bolder;
}

.option-btn {
    border: thin solid;
    border-color: #514773 !important;
    font-size: 0.9rem;
    max-width: 100%;
}
</style>
