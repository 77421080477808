<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="850" content-class="hide-overflow">
            <v-card class="border-radius-3 pa-5">
                <v-btn color="primary" icon class="position-absolute btn-cls" @click="close">
                    <v-icon color="primary" class="font-size-36">mdi-close-circle</v-icon>
                </v-btn>
                <v-row justify="center" align="center" class="flex-column mb-2">
                    <v-card-title class="headline d-flex justify-center brown--text darken-3">
                        <v-img width="30" :src="commentImg" class="mx-1" />
                        <span class="titles text-h5 d-flex justify-center">
                            {{ totalRatings }} {{ $t('bookReading.comments') }}
                        </span>
                    </v-card-title>
                    <v-card-subtitle>
                        <span
                            class="text-subtitle-2 font-weight-bold brown--text darken-3 mt-3 d-flex justify-center"
                            >{{ $t('about') }} {{ bookTitle }}
                        </span>
                    </v-card-subtitle>
                </v-row>
                <v-container class="headline height-viewport-100">
                    <div class="d-flex justify-center comments-content" id="comment-content-id">
                        <v-row class="pa-0 ma-0 main-categories">
                            <v-col
                                cols="12"
                                v-for="(comment, index) of ratings"
                                :key="index"
                                class="pa-0 d-flex justify-center mb-2"
                            >
                                <Comments
                                    :comment_data="comment"
                                    :rating="comment.rating == 0 ? 1 : comment.rating"
                                ></Comments>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="d-flex justify-center align-center" v-if="loading">
                        <v-row class="pa-0 ma-0 main-categories">
                            <v-col cols="12" class="pa-0 d-flex justify-center mt-2">
                                <v-progress-circular
                                    indeterminate
                                    color="secondary"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import BookRatingService from '@/services/bookRating'

import Comments from './items/Comments.vue'

export default {
    props: {
        dialog: { type: Boolean, default: false },
        bookTitle: { type: String, default: '' },
        totalRatings: { type: [String, Number], default: '' },
    },
    components: {
        Comments,
    },
    computed: {
        ...mapState({
            pageNumber: (state) => state.bookReading.pageNumber,
            bookId: (state) => state.bookReading.book.id,
            ratings: (state) => state.bookReading.bookRatingData,
        }),
    },
    data() {
        // eslint-disable-next-line global-require
        return { commentImg: require('@/assets/img/icons/comment.svg'), loading: false }
    },
    methods: {
        ...mapActions('bookReading', ['increasePageNumber', 'concatBookRatingData']),
        close() {
            this.$emit('close-modal')
        },
        async searchMoreComments() {
            this.loading = true
            this.increasePageNumber()
            const response = await BookRatingService.getRatingsByBook({
                bookId: this.bookId,
                number: this.pageNumber,
            })
            this.concatBookRatingData(response.content.content)
            this.loading = false
        },
    },
    mounted() {
        const vm = this
        setTimeout(() => {
            const content = document.querySelector('#comment-content-id')
            if (content) {
                content.addEventListener('scroll', () => {
                    if (vm.totalRatings === vm.ratings.length) return
                    const tempScrollTop = content.scrollTop
                    const totalHeight = tempScrollTop + content.offsetHeight
                    if (content.scrollHeight === totalHeight) {
                        vm.searchMoreComments()
                        content.scrollTop = tempScrollTop
                    }
                })
            }
        }, 0)
    },
}
</script>
<style>
.hide-overflow {
    overflow-y: hidden !important;
}
.headline .titles {
    font-family: 'Space Grotesk', cursive !important;
}

.comments-content {
    height: 60vh;
    max-height: 60vh;
    overflow: auto;
}

.main-categories {
    justify-content: center;
    align-items: self-start;
    align-content: start;
}

.category-img {
    display: block;
    max-width: 52vh;
    height: 5vh;
}

.sm-category-img {
    display: block;
    max-width: 52vh;
    height: 4vh;
}

.category-active .category-box {
    border: 1vh solid #4caf50;
}

.category-check {
    display: none;
}
.category-active .category-check {
    bottom: -12px;
    right: 0px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
}

.category-active .sm-category-img {
    height: 5vh;
}
</style>
