<template>
    <UtilPanelContent>
        <template v-slot:utils>
            <div v-if="stShowIndex">
                <Index @close-index="showIndex" class="elevation-11 border-radius-1" />
            </div>
            <div v-if="stShowSettings">
                <Settings @close-settings="showSettings" class="elevation-11 border-radius-1" />
            </div>
            <div v-if="stShowQuestions">
                <Questions @close-question="showQuestions" class="elevation-11 border-radius-1" />
            </div>
            <div v-if="stShowDictionary">
                <Dictionary
                    @close-dictionary="openDictionary"
                    v-if="stShowDictionary"
                    class="elevation-11 border-radius-1"
                />
            </div>
        </template>
        <div
            :class="
                'mx-auto border-radius-1 transition-all util-bar ' +
                (showTexts ? 'util-bar-text' : 'util-bar-non-text')
            "
        >
            <div
                class="d-flex flex-column justify-center align-center util-bar-border content-shadow w-100"
                @mouseover="showTexts = true"
                @mouseleave="showTexts = false"
            >
                <div class="border-top w-100">
                    <v-btn
                        text
                        block
                        class="w-100 ma-0 py-6 d-flex justify-end align-center"
                        @click="showIndex"
                    >
                        <div
                            class="d-flex flex-column justify-center align-end option--primary ml-15 mr-2 transition-all"
                            v-if="showTexts"
                        >
                            <span class="text-capitalize">{{ texts.indexTxt.text }} </span>
                            <span class="font-size-9 font-weight-light opacity-6 text-capitalize"
                                >{{ texts.indexTxt.trl }}
                            </span>
                        </div>
                        <img class="reading-util-icon" :src="indexImg" alt="index image icon" />
                    </v-btn>
                    <v-btn
                        text
                        block
                        class="w-100 ma-0 py-6 d-flex justify-end align-center"
                        @click="showSettings"
                    >
                        <div
                            class="d-flex flex-column justify-center align-end option--primary ml-15 mr-2"
                            v-if="showTexts"
                        >
                            <span class="text-capitalize">{{ texts.settingTxt.text }} </span>
                            <span class="font-size-9 font-weight-light opacity-6 text-capitalize"
                                >{{ texts.settingTxt.trl }}
                            </span>
                        </div>
                        <img class="reading-util-icon" :src="settingImg" />
                    </v-btn>
                    <v-btn
                        text
                        block
                        :class="
                            'w-100 ma-0 py-6 d-flex justify-end align-center ' +
                            (hasQuestion ? 'animated infinite bounce delay-2s' : '')
                        "
                        @click="showQuestions"
                        id="btn-question"
                    >
                        <div
                            class="d-flex flex-column justify-center align-end option--primary ml-15 mr-2"
                            v-if="showTexts"
                        >
                            <span class="text-capitalize">{{ texts.questionsTxt.text }} </span>
                            <span class="font-size-9 font-weight-light opacity-6 text-capitalize"
                                >{{ texts.questionsTxt.trl }}
                            </span>
                        </div>
                        <img
                            class="reading-util-icon"
                            :src="questionsImg"
                            alt="question image icon"
                        />
                    </v-btn>
                </div>
                <div class="bottom-radius border-bottom w-100">
                    <v-btn
                        block
                        color="transparent"
                        elevation="0"
                        class="w-100 ma-0 border-radius-0 py-6 d-flex justify-end align-center"
                        @click="openDictionary"
                    >
                        <div
                            class="d-flex flex-column justify-center align-end option--white ml-15 mr-2"
                            v-if="showTexts"
                        >
                            <span class="text-capitalize">{{ texts.dictionaryTxt.text }} </span>
                            <span class="font-size-9 font-weight-light opacity-6 text-capitalize"
                                >{{ texts.dictionaryTxt.trl }}
                            </span>
                        </div>
                        <img class="reading-util-icon" :src="dictionaryImg"/>
                    </v-btn>
                </div>
            </div>
        </div>
    </UtilPanelContent>
</template>
<script>
/* eslint-disable global-require */
import { mapActions, mapState } from 'vuex'

import UtilPanelContent from '@/components/library/preview/core/UtilPanelContent.vue'
import Index from './bookUtils/Index.vue'
import Settings from './bookUtils/Settings.vue'
import Questions from './bookUtils/Questions.vue'
import Dictionary from './bookUtils/Dictionary.vue'

export default {
    components: {
        Index,
        Settings,
        Questions,
        Dictionary,
        UtilPanelContent,
    },
    data() {
        return {
            showTexts: false,
            indexImg: require('@/assets/img/icons/reading/1-index.svg'),
            settingImg: require('@/assets/img/icons/reading/2-settings.svg'),
            questionsImg: require('@/assets/img/icons/reading/3-questions.svg'),
            dictionaryImg: require('@/assets/img/icons/reading/6-diccionary.svg'),
        }
    },
    methods: {
        ...mapActions('bookReading', [
            'showIndex',
            'showSettings',
            'showQuestions',
            'showDictionary',
            'setDictionaryMode',
        ]),
        openDictionary() {
            this.setDictionaryMode('manual')
            this.showDictionary()
        },
    },
    computed: {
        ...mapState({
            stShowIndex: (state) => state.bookReading.showIndex,
            stShowSettings: (state) => state.bookReading.showSettings,
            stShowQuestions: (state) => state.bookReading.showQuestions,
            stShowDictionary: (state) => state.bookReading.showDictionary,
            hasQuestion: (state) => state.bookReading.hasQuestion,
        }),
        texts() {
            return {
                indexTxt: {
                    text: this.$t('bookReading.index'),
                    trl: this.$i18n.locale === 'es' ? 'Index' : 'Índice',
                },
                settingTxt: {
                    text: this.$t('bookReading.settings'),
                    trl: this.$i18n.locale === 'es' ? 'Settings' : 'Ajustes',
                },
                questionsTxt: {
                    text: this.$t('bookReading.question'),
                    trl: this.$i18n.locale === 'es' ? 'Questions' : 'Preguntas',
                },
                dictionaryTxt: {
                    text: this.$t('bookReading.dictionary'),
                    trl: this.$i18n.locale === 'es' ? 'Dictionary' : 'Diccionario',
                },
            }
        },
    },
}
</script>

<style>
.bg-purple {
    background: #514773;
    border: 1.5px solid #514773;
}
.option--primary {
    color: #514773;
}
.option--white {
    color: white;
}
/* .text-sm{
    font-size: 12px
} */
.util-bar {
    z-index: 5;
}

.util-bar-text {
    width: 12.5em !important;
}

.util-bar-non-text {
    width: 3.5em;
}
.util-bar-border {
    border-radius: 1vh;
}

.bottom-radius {
    border-radius: 0 0 1vh 1vh !important;
}

.border-top {
    border: 1.5px solid #514773 !important;
    border-radius: 1vh 1vh 0 0 !important;
    background: white;
}
.border-bottom {
    border: 1.5px solid #514773 !important;
    background: #514773;
}

.content-shadow {
    box-shadow: 0 6px 7px -4px rgba(0, 0, 0, 0.2), 0 11px 15px 1px rgba(0, 0, 0, 0.14),
        0 4px 20px 3px rgba(0, 0, 0, 0.12) !important;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .reading-util-icon {
        width: 2.5vh;
    }

    .util-bar {
        right: 6em;
        top: 5rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .reading-util-icon {
        width: 2.5vh;
    }
    .util-bar {
        right: 8em;
        top: 5rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .reading-util-icon {
        width: 3vh;
    }
    .util-bar {
        right: 10em;
        top: 5rem;
    }
}
</style>
