<template>
    <div class="position-relative">
        <div
            v-show="showMenu"
            class="menu primary border-radius-1"
            :style="{
                left: `${x}px`,
                top: `${y}px`,
                'z-index': `100`,
            }"
        >
            <div v-if="useDictionary"
                class="item cursor-pointer transition-all mx-3 d-flex flex-column justify-center align-center"
                @mousedown.prevent="handleAction('dictionary')"
            >
                <em class="fas fa-search"/>
                <span class="mt-2">Buscar Palabra</span>
            </div>
        </div>
        <div id="hlg-cnt" class="position-relative">
            <slot />
        </div>
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        props: {
            useDictionary: { type: Boolean, default: true },
            useHighlight: { type: Boolean, default: true },
            useNotes: { type: Boolean, default: true },
        },
        computed: {
            ...mapState({
                currentPage: (state) => state.bookReading.currentPage,
            }),
            highlightableEl() {
                return this.$slots.default[0].elm
            },
        },
        data() {
            return {
                x: 0,
                y: 0,
                showMenu: false,
                selectedText: '',
                rangeElement: null,
                offsetValues: null,
                pageChildIndex: null,
            }
        },
        mounted() {
            setTimeout(() => {
                const main = document.getElementById('main')
                if (main) main.addEventListener('mouseup', this.onMouseup)
            }, 0)
        },

        beforeDestroy() {
            const main = document.getElementById('main')
            if (main) main.removeEventListener('mouseup', this.onMouseup)
        },
        methods: {
            ...mapActions('bookReading', ['setIdiomId', 'setIdiomParentId', 'showIdiomModal']),
            onMouseup(e) {
                const isIdiom = e.srcElement.getAttribute('data-is-idiom') === 'true'

                if (isIdiom) {
                    this.setIdiomId(e.srcElement.getAttribute('data-idiom-id'))
                    this.setIdiomParentId(e.srcElement.getAttribute('data-parent-id'))
                    this.showIdiomModal(isIdiom)
                    return
                }
                const selection = window.getSelection()
                let selectionRange = null
                if (!selection.rangeCount) {
                    return
                }
                selectionRange = selection.getRangeAt(0)
                this.selectedText = selection.toString()

                const { height } = selectionRange.getBoundingClientRect()

                if (height < 1) {
                    this.resetVars()
                    return
                }

                if (!this.selectedText) {
                    this.resetVars()
                    return
                }
                // Get the x, y, and width of the selection

                this.x = e.layerX
                this.y = e.layerY - height
                this.rangeElement = this.getRangeElement(selectionRange, this.currentPage)
                this.pageChildIndex = this.rangeElement.pageChildIndex
                this.offsetValues = this.getOffsetValue(
                    this.rangeElement.structuralParentElement,
                    selectionRange,
                )
                this.showMenu = true
            },
            handleAction(action) {
                this.$emit(action, this.selectedText)
                this.resetVars()
            },
            resetVars() {
                this.showMenu = false
                this.x = 0
                this.y = 0
                this.selectedText = ''
                if (window.getSelection) {
                    if (window.getSelection().empty) {
                        // Chrome
                        window.getSelection().empty()
                    } else if (window.getSelection().removeAllRanges) {
                        // Firefox
                        window.getSelection().removeAllRanges()
                    }
                } else if (document.selection) {
                    // IE?
                    document.selection.empty()
                }
            },
            getRangeElement(range, pageId) {
                let child = range.commonAncestorContainer
                while (child.parentElement && child.parentElement.id !== `bookpage_${pageId}`) {
                    child = child.parentElement
                }
                let childElement = child
                let i = 0
                // tslint:disable-next-line: no-conditional-assignment
                // eslint-disable-next-line no-cond-assign
                for (i = 0; (childElement = childElement.previousElementSibling); i += 1) {
                    // eslint-disable-next-line no-empty
                }
                return {
                    structuralParentElement: child,
                    pageChildIndex: i,
                }
            },
            getOffsetValue(element, range) {
                const preCaretRange = range.cloneRange()
                preCaretRange.selectNodeContents(element)
                preCaretRange.setEnd(range.startContainer, range.startOffset)
                const startOffset = preCaretRange.toString().length
                const endOffset = startOffset + range.toString().length
                return {
                    startOffset,
                    endOffset,
                }
            },
        },
    }
</script>

<style scoped>
  .menu {
    height: 12.5vh;
    padding: 10px 10px;
    position: absolute;
    transform: translate(-50%, -100%);
    transition: 0.2s all;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 35vh;
  }

  .menu:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -1.5vh;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 2.5vh solid transparent;
    border-right: 2.5vh solid transparent;
    border-top: 2.5vh solid #514773;
    z-index: 1;
  }

  .item {
    color: #fff;
    cursor: pointer;
  }

  .item:hover {
    cursor: pointer;
    color: rgb(211, 211, 211);
  }

  .item span {
    font-size: 12px;
    line-height: 2vh;
    font-weight: lighter !important;
  }
</style>
