<template>
    <div class="d-flex justify-center align-center position-relative">
        <img :src="imgShape" alt="" class="position-absolute bg-shape" />
        <div class="idiom-text">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'shape-content',
    data() {
        return {
            // eslint-disable-next-line global-require
            imgShape: require('@/assets/img/effects/shape-002.svg'),
        }
    },
}
</script>
<style>
.bg-shape {
    width: 35vh;
    height: 25vh;
}

.idiom-text {
    z-index: 10;
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    /* .bg-shape {
        width: 40vh;
        height: 35vh;
    } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    /* .bg-shape {
        width: 40vh;
        height: 35vh;
    } */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    /* .bg-shape {
        width: 40vh;
        height: 40vh;
    } */
}
</style>
