<template>
    <div
        :class="
            'font-family-selector d-flex justify-center font-weight-bold align-center font-size-14 mr-3 px-3 py-2 ' +
            (active ? 'font-family-active' : 'font-family-non-active')
        "
        :style="`font-family: ${font_family.fontFamilyValue} !important`"
        @click="setConfigs"
    >
        {{ font_family.fontFamilyName }}
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        font_family: { type: Object, default: null },
        active: { type: Boolean, default: false },
    },
    computed: {
        ...mapState({
            bookReadConf: (state) => state.bookReading.bookReadConf,
            book: (state) => state.bookReading.book,
            user: (state) => state.userData.user,
        }),
    },
    methods: {
        ...mapActions('bookReading', ['setFontFamily']),
        setConfigs() {
            this.setFontFamily(this.font_family.fontFamilyId)
        },
    },
}
</script>
<style>
.font-family-selector {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) !important;
    transition: background-image 0.5s ease;
    border-radius: 5px;
}
.font-family-selector:hover {
    cursor: pointer;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) !important;
}

.font-family-non-active {
    border: 1.5px solid #514773 !important;
    background: white;
    color: #514773;
}

.font-family-active {
    border: 1.5px solid #514773 !important;
    background: #514773 !important;
    color: white !important;
}
</style>
