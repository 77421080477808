<template>
    <div
        :class="
            'w-90 d-flex justify-start chapter-content align-center pa-3 mx-3 border-radius-0 mb-2 ' +
            (active ? 'chapter-active' : 'chapter-text')
        "
        @click="setChapter"
    >
        <span
            >{{ $t('bookReading.chapter') }} {{ chapter_data.chapterNumber }}.
            {{ chapter_data.chapterTitle }}</span
        >
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        chapter_data: { type: Object, default: null },
        active: { type: Boolean, default: false },
    },
    computed: {
        ...mapState({
            currentStage: (state) => state.bookReading.currentStage,
            currentPage: (state) => state.bookReading.currentPage,
            currentChapter: (state) => state.bookReading.currentChapter,
            currentChapterData: (state) => state.bookReading.currentChapterData,
        }),
    },
    methods: {
        ...mapActions('bookReading', [
            'setCurrentPage',
            'setCurrentChapter',
            'setCurrentChapterData',
        ]),
        setChapter() {
            window.scrollTo(0, 0)
            let bookPage = document.querySelector(`#bookpage_${this.currentPage}`)
            if (bookPage) bookPage.style.display = 'none'

            let chapterHtml = document.querySelector(`#chapter_${this.currentChapter}`)
            if (chapterHtml) chapterHtml.style.display = 'none'

            this.setCurrentChapter(this.chapter_data.chapterNumber)
            this.setCurrentPage(this.chapter_data.structInitialPage)

            chapterHtml = document.querySelector(`#chapter_${this.currentChapter}`)
            if (chapterHtml) chapterHtml.style.display = 'block'

            const currentPage = document.querySelector(`.current-page`)
            if(currentPage) currentPage.classList.toggle('current-page')

            bookPage = document.querySelector(`#bookpage_${this.currentPage}`)
            if (bookPage) {
                bookPage.style.display = 'flex'
                bookPage.classList.toggle('current-page')
            }

            this.setCurrentChapterData(this.chapter_data)
        },
    },
}
</script>

<style>
.chapter-content {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) !important;
    transition: background-image 0.5s ease;
}
.chapter-content:hover {
    cursor: pointer;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) !important;
    border-radius: 2px;
}
.chapter-text {
    border: 1.5px solid #514773 !important;
    background: white;
    color: #514773;
}

.chapter-active {
    border: 1.5px solid #514773 !important;
    background: #514773 !important;
    color: white !important;
}
</style>
