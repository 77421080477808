<template>
    <v-row :class="'main-container mt-6 '">
        <v-col>
            <v-card
                class="mx-auto border-radius-3 pa-7 position-relative opacity-95"
                max-width="100%"
                outlined
                elevation="5"
                id="main"
            >
                <v-list-item two-line>
                    <v-list-item-content class="br-title">
                        <div class="text-h4 mb-2">{{ book.title }}</div>
                        <v-list-item-title class="text-subtitle-1 mt-0">
                            {{ author }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-card-text
                    class="mt-4 pl-10 pr-10"
                    :style="`font-size:${
                        bookReadConf ? bookReadConf.fontSize : ''
                        }px !important;font-family:${mainFont}!important;`"
                >
                    <Highlightable
                        ref="highlightable"
                        @dictionary="onDictionary"
                    >
                        <div v-if="book.formatType === 'EPUB'">
                            <EpubPage
                                v-for="(page, index) of epubPages"
                                :key="index"
                                :resource="book.resourceDir"
                                :page="page.href"
                                :page_id="page.number"
                            />
                        </div>
                        <div v-else>
                            <Pages
                                v-for="(chapter, index) of book.bookChapterDTOList"
                                :key="index"
                                :resource="book.resourceDir"
                                :chapter="chapter"
                            />
                        </div>
                    </Highlightable>
                </v-card-text>

                <v-card-actions
                    class="d-flex justify-center align-center"
                    v-if="currentStage === 'bookReading'"
                >
                    <app-footer ref="appFooter" class="d-block"/>
                </v-card-actions>
                <div
                    class="cursor-crosshair position-left opacity-1 black position-absolute pre-note-container border-radius-3"
                    @mousedown.prevent="getRelativeCoordinates"
                    v-if="showPanel"
                />
                <v-btn
                    class="position-absolute rounded-circle pa-0"
                    fab
                    color="secondary"
                    :style="{ left: `${x_axis}px`, top: `${y_axis}px` }"
                    v-if="x_axis || y_axis"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-card>
            <UtilsPanel class="position-fixed"/>
        </v-col>
            <IdiomModal
                :dialog="showIdiomModal"
                v-if="idiomId && idiomParentId"
                :main_idiom="parentIdiom"
                :current_country="idiomId"
                @close-modal="resetIdioms"
            />
    </v-row>
</template>
<script>
    /* eslint-disable no-undef */

    import { mapActions, mapState } from 'vuex'

    import Highlightable from '@/components/library/preview/core/Highlightable.vue'
    import Pages from './Pages.vue'
    import EpubPage from './EpubPage.vue'
    import UtilsPanel from './UtilsPanel.vue'
    import IdiomModal from './modals/IdiomModal.vue'
    import Author from '../../../../mixins/author'

    export default {
        components: {
            Pages,
            EpubPage,
            Highlightable,
            UtilsPanel,
            IdiomModal,
        },
        mixins: [Author],
        computed: {
            ...mapState({
                bookReadConf: (state) => state.bookReading.bookReadConf,
                book: (state) => state.bookReading.book,
                markerWords: (state) => state.bookReading.markerWords,
                epubPages: (state) => state.bookReading.epubPages,
                currentChapter: (state) => state.bookReading.currentChapter,
                currentChapterData: (state) => state.bookReading.currentChapterData,
                currentPage: (state) => state.bookReading.currentPage,
                currentStage: (state) => state.bookReading.currentStage,
                user: (state) => state.userData.user,
                linguisticVariables: (state) => state.common.linguisticVariables,
                showIdiomModal: (state) => state.bookReading.showIdiomModal,
                idiomParentId: (state) => state.bookReading.idiomParentId,
                idiomId: (state) => state.bookReading.idiomId,
            }),
            mainFont() {
                switch (this.bookReadConf.fontFamily) {
                    case '0':
                        return '"Times New Roman", Times, serif'
                    case '1':
                        return "'Roboto', sans-serif"
                    case '2':
                        return "'Comic Sans MS', arial"
                    case '3':
                        return "'Arial', fantasy"
                    default:
                        return 'Arial'
                }
            },
            parentIdiom() {
                const vm = this
                return this.linguisticVariables.find((el) => el.id === Number(vm.idiomParentId))
            },
        },
        data() {
            return {
                selectedText: '',
                showPanel: false,
                x_axis: 0,
                y_axis: 0,
                startOffset: null,
                endOffset: null,
                pageChildIndex: null,
            }
        },
        methods: {
            ...mapActions('bookReading', [
                'showDictionary',
                'setDictionaryMode',
                'setWord',
                'resetIdioms',
            ]),
            onDictionary(text) {
                const clean = text.replace(' ', ',')
                const arr = clean.split(',')
                this.resetVars()
                if (arr.length > 1 && arr[1]) {
                    this.$modal.warning(this.$t('bookReading.warnDictionary'))
                    return
                }
                this.setDictionaryMode('auto')
                this.setWord(arr[0])
                this.showDictionary()
            },
            resetVars() {
                this.selectedText = ''
                this.x_axis = 0
                this.y_axis = 0
            },
            getRelativeCoordinates(e) {
                const rect = e.target.getBoundingClientRect()
                const x = e.clientX - rect.left
                const y = e.clientY - rect.top
                this.x_axis = x.toFixed()
                this.y_axis = y.toFixed()
            },
            viewportToHost(viewportRectangle = null, rangeContainer = null, hostContainer = null) {
                const hostRectangle = hostContainer.getBoundingClientRect()

                // Both the selection rectangle and the host rectangle are calculated relative to
                // the browser viewport. As such, the local position of the selection within the
                // host element should just be the delta of the two rectangles.
                let localLeft = (viewportRectangle.left - hostRectangle.left)
                let localTop = (viewportRectangle.top - hostRectangle.top)

                let node = rangeContainer
                // Now that we have the local position, we have to account for any scrolling
                // being performed within the host element. Let's walk from the range container
                // up to the host element and add any relevant scroll offsets to the calculated
                // local position.
                do {
                    localLeft += node.scrollLeft
                    localTop += node.scrollTop
                    // tslint:disable-next-line: no-conditional-assignment
                } while ((node !== hostContainer) && (node = node.parentNode))
                return ({
                    left: localLeft,
                    top: localTop,
                    width: viewportRectangle.width,
                    height: viewportRectangle.height,
                })
            },
            recreateRange(offsets, containerElement) {
                let charIndex = 0
                const range = new Range()
                range.setStart(containerElement, 0)
                range.collapse(true)
                const nodeStack = [containerElement]
                let node
                let foundStart = false
                let stop = false

                while (!stop && (node = nodeStack.pop())) {
                    if (node.nodeType === 3) {
                        const nextCharIndex = charIndex + node.length
                        if (!foundStart && offsets.startOffset >= charIndex && offsets.startOffset <= nextCharIndex) {
                            range.setStart(node, offsets.startOffset - charIndex)
                            foundStart = true
                        }
                        if (foundStart && offsets.endOffset >= charIndex && offsets.endOffset <= nextCharIndex) {
                            range.setEnd(node, offsets.endOffset - charIndex)
                            stop = true
                        }
                        charIndex = nextCharIndex
                    } else {
                        let i = node.childNodes.length
                        while (i--) {
                            nodeStack.push(node.childNodes[i])
                        }
                    }
                }
                return range
            },
            getLocalRectangle(range, bookContentContainer) {
                return this.viewportToHost(range.getBoundingClientRect(), this.getRangeContainer(range), bookContentContainer)
            },
            getRangeContainer(range) {
                let container = range.commonAncestorContainer
                while (container.nodeType !== 1) {
                    container = container.parentNode
                }
                return container
            },
        },
    }
</script>
<style>
  .chapter_title {
    font-size: 36px;
    font-weight: bolder;
    text-align: center;
    padding-bottom: 1em;
  }

  .bookpage {
    text-align: justify;
    line-height: 1.8rem;
  }

  .br-title,
  .chapter_title,
  .bookpage,
  .footer-text {
    color: #4a3216;
  }
  .bookpage .ilustration, .bookpage .illustration {
      text-align: center;
  }

  .bookpage .ilustration > img, .bookpage .illustration > img {
      width: 95%;
  }

  .stanza_content {
      margin-top: 0;
      margin-bottom: 0 !important;
  }

  .pre-note-container {
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .idioms {
    border: 0px;
    border-bottom: 2px #4e342e;
    border-style: dotted;
  }

  .idioms:hover {
    cursor: pointer;
  }
</style>
