<template>
    <div>
        <UtilsContent @close-action="closeDictionary">
            <template v-slot:header>
                <div class="d-flex flex-column justify-center align-start">
                    <div class="d-flex flex-row justify-start align-center mx-3 mb-4">
                        <img :src="dictionaryImg" class="img-index mr-2" alt="" /><span
                            class="text-h5 ma-0 text--primary"
                            >{{ $t('bookReading.dictionary') }}</span
                        >
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div class="d-flex flex-column mx-3">
                    <v-row justify="start" align="start">
                        <v-col cols="12">
                            <v-text-field
                                :label="$t('bookReading.dictionary_msg_1')"
                                outlined
                                class="white"
                                color="primary"
                                hide-details
                                clearable
                                @click:clear="clear"
                                v-model="st.word"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row
                        justify="start"
                        align="start"
                        v-if="dictionary_data.length"
                        class="dictionary-content"
                    >
                        <v-col cols="12" v-for="(meaning, index) of dictionary_data" :key="index">
                            <MeaningItem :data="meaning" />
                        </v-col>
                    </v-row>
                    <v-row v-if="noMeaning">
                        {{ $t('bookReading.no_meaning') }}
                    </v-row>
                </div>
            </template>
            <template v-slot:actions>
                <v-row justify="center" align="center" v-if="!dictionary_data.length">
                    <v-btn
                        color="secondary"
                        rounded
                        class="mt-4 mx-3 w-90 py-5 white--text"
                        :loading="btnLoading"
                        @click="search"
                        >{{ $t('bookReading.search_word') }}</v-btn
                    >
                </v-row>
            </template>
        </UtilsContent>
    </div>
</template>
<script>
/* eslint-disable global-require */
import { mapState, mapActions } from 'vuex'
import * as Sentry from '@sentry/vue'
import global from '@/constants/GlobalConstant'
import UtilsContent from '@/components/library/preview/core/UtilsContent.vue'
import MeaningItem from './items/MeaningItem.vue'

export default {
    components: {
        UtilsContent,
        MeaningItem,
    },
    computed: {
        ...mapState({
            st: (state) => state.bookReading,
            dictionaryMode: (state) => state.bookReading.dictionaryMode,
            word: (state) => state.bookReading.word,
        }),
    },
    mounted() {
        if (this.dictionaryMode === 'auto') this.search()
    },
    data() {
        return {
            dictionaryImg: require('@/assets/img/icons/reading/icon-diccionary-2.svg'),
            global,
            dictionary_data: [],
            btnLoading: false,
            noMeaning: false,
        }
    },
    methods: {
        ...mapActions('bookReading', ['setWord']),
        closeDictionary() {
            this.clear()
            this.$emit('close-dictionary')
        },
        clear() {
            this.setWord('')
            this.dictionary_data.length = 0
        },
        async search() {
            const vm = this
            this.btnLoading = true
            this.dictionary_data.length = 0
            await fetch(`${this.global.DICTIONARY_URL_API}${this.word}`)
                .then((response) => {
                    response.json().then((j) => {
                        if (j.title) {
                            this.noMeaning = true
                            return
                        }
                        this.noMeaning = false
                        j.forEach((item) => {
                            const word = { word: item.word, origin: item.origin }
                            // eslint-disable-next-line no-restricted-syntax
                            for (const name in item.meaning) {
                                // eslint-disable-next-line no-prototype-builtins
                                if (item.meaning.hasOwnProperty(name)) {
                                    word.meaning = name
                                    word.definitions = item.meaning[name]
                                }
                            }
                            vm.dictionary_data.push(word)
                        })
                    })
                })
                .catch((error) => Sentry.captureException(error))
            this.btnLoading = false
        },
    },
}
</script>
<style>
.chapters-container {
    height: 35vh;
    max-height: 35vh;
    overflow: auto;
}

.dictionary-content {
    height: 55vh;
    max-height: 55vh;
    overflow: auto;
}
</style>
