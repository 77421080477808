var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"550","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center elevation-15 higlight-type-content position-relative"},[_c('v-btn',{staticClass:"position-absolute btn-cls",attrs:{"color":"primary","icon":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close-circle")])],1),_c('div',{staticClass:"d-flex flex-column justify-center align-center position-relative pa-8"},[(_vm.main_idiom)?_c('div',{staticClass:"py-3 d-flex flex-column justify-center align-center"},[_c('span',{staticClass:"text-subtitle-1 primary--text font-weight-bolder"},[_vm._v(_vm._s(`¿Sabías que la palabra "${_vm.main_idiom.word}" tiene diferentes significado en ${_vm.main_idiom.countries.length} paises`))])]):_vm._e(),_c('v-row',{staticClass:"main-idiom-img mb-5 d-flex flex-row justify-center align-center position-relative"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"4"}},[_c('img',{staticClass:"rounded-circle variable-img",attrs:{"src":`https://d5seczr4segy5.cloudfront.net/lingual_variable_image/${_vm.main_idiom.urlImg}`,"alt":"imgen de variable linguistica"}})]),_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"8"}},[_c('SplashContent',[_c('span',{staticClass:"text-body-1 font-weight-bold primary--text ml-10 font-size-16",domProps:{"innerHTML":_vm._s(
                                `En
                        <b class="text-capitalize"
                            >${_vm.currentCountry.country.name}</b
                        >
                        la palabra "${_vm.currentCountry.word}" se usa para referirse al
                        "${_vm.main_idiom.word}"`
                            )}})])],1)],1),(_vm.main_idiom)?_c('div',{staticClass:"d-flex flex-row justify-center align-center flags-control w-80 mt-5"},[(_vm.main_idiom.countries.length >= 5)?_c('v-btn',{staticClass:"mx-3 secondary",attrs:{"icon":""},on:{"click":_vm.moveRigth}},[_c('v-icon',{staticClass:"white--text icon-rotate"},[_vm._v(" mdi-trending-neutral")])],1):_vm._e(),_c('div',{class:'w-75 position-relative flags-content d-flex align-center flex-row ' +
                        (_vm.main_idiom.countries.length >= 5 ? '' : 'justify-center'),attrs:{"id":"flags-carrousel"}},_vm._l((_vm.main_idiom.countries),function(country,index){return _c('CountryFlag',{key:index,staticClass:"pa-0",attrs:{"iso_code":country.country.isoCode,"active":country.id === Number(_vm.current_country)},on:{"flag-click":function($event){return _vm.setCountry(country.id)}}})}),1),(_vm.main_idiom.countries.length >= 5)?_c('v-btn',{staticClass:"mx-3 secondary",attrs:{"icon":""},on:{"click":_vm.moveLeft}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(" mdi-trending-neutral")])],1):_vm._e()],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }