<template>
    <div
        :class="
            'font-size-selector font-weight-light d-flex justify-center align-center py-2 px-3 ' +
            (active ? 'font-size-active' : 'font-size-non-active')
        "
        :style="`font-size: ${font_size}px`"
        @click="setConfigs"
    >
        <slot></slot>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        font_size: { type: [String, Number], default: '' },
        active: { type: Boolean, default: false },
    },
    computed: {
        ...mapState({
            bookReadConf: (state) => state.bookReading.bookReadConf,
            book: (state) => state.bookReading.book,
            user: (state) => state.userData.user,
        }),
    },
    methods: {
        ...mapActions('bookReading', ['setFontSize']),
        setConfigs() {
            this.setFontSize(this.font_size)
        },
    },
}
</script>
<style>
.font-size-selector {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) !important;
    transition: background-image 0.5s ease;
    max-height: 7vh;
}
.font-size-selector:hover {
    cursor: pointer;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) !important;
}

.font-size-non-active {
    border: 1.5px solid #514773 !important;
    background: white;
    color: #514773;
}

.font-size-active {
    border: 1.5px solid #514773 !important;
    background: #514773 !important;
    color: white !important;
}
</style>
