<template>
    <div class="d-flex flex-row mx-auto border-radius-1 transition-all util-bar">
        <slot name="utils"></slot>
        <slot></slot>
    </div>
</template>
<script>
export default {
    mounted() {
        console.debug('Init util panel')
    },
}
</script>
