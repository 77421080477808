<template>
    <div
        class="bookpage flex-column justify-center"
        v-html="content"
        :id="`bookpage_${page_id}`"
        :style="`display: none;
        font-size:${
            bookReadConf ? bookReadConf.fontSize : ''
        }px !important;font-family:${mainFont}!important;`"
    ></div>
</template>
<script>
// /* eslint-disable no-undef */

import { mapActions, mapState } from 'vuex'

import BookService from '@/services/book'
import global from '@/constants/GlobalConstant'

export default {
    props: {
        resource: { type: String, default: '' },
        page: { type: String, default: '' },
        page_id: { type: [String, Number], default: '' },
    },
    computed: {
        ...mapState({
            bookReadConf: (state) => state.bookReading.bookReadConf,
        }),
        mainFont() {
            switch (this.bookReadConf.fontFamily) {
                case '0':
                    return '"Times New Roman", Times, serif'
                case '1':
                    return "'Roboto', sans-serif"
                case '2':
                    return "'Comic Sans MS', arial"
                case '3':
                    return "'Arial', fantasy"
                default:
                    return 'Arial'
            }
        },
    },
    data() {
        return {
            content: '',
            global,
        }
    },
    async mounted() {
        const page = await BookService.getEPUBBookPage(this.resource, this.page)
        let content = await page.text()
        if (content.includes('../')) {
            content = content.replaceAll(
                '../',
                `${this.global.BASE_URL_BOOK}${this.resource}/OEBPS/`
            )
        }
        this.content = content
        this.increaseEpubPagesLoaded()
    },
    methods: {
        ...mapActions('bookReading', ['increaseEpubPagesLoaded']),
    },
}
</script>

<style>
.bookpage img {
    max-width: 100%;
}
</style>
