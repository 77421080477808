<template>
    <v-container class="pa-3 meaning-content elevation-5 border-radius-2">
        <v-row justify="start" align="start" v-if="data">
            <v-col cols="12">
                <span
                    class="font-size-24 text-capitalize secondary--text darken-3 font-weight-bolder text-left"
                    >{{ meaning }}</span
                >
            </v-col>
            <v-col
                cols="12"
                class="px-3 d-flex flex-column align-center justify-center"
                v-for="(def, index) of data.definitions"
                :key="index"
            >
                <div class="border-bottom-dotted w-90 px-5 pb-3 d-flex flex-column justify-center">
                    <div class="text-justify mb-2">
                        <span class="text-body-1 brown--text lighten-3 text-justify">
                            <b>{{ index + 1 }}.</b> {{ def.definition }}</span
                        >
                    </div>
                    <div class="mr-2 text-left" v-if="def.example">
                        <span class="text-body-1 brown--text lighten-3 "
                            ><b>{{ $t('bookReading.example') }}:</b> {{ def.example }}</span
                        >
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: {
        data: { type: Object, default: null },
    },
    computed: {
        meaning() {
            return `${this.data.word} - ${this.data.meaning} `
        },
    },
}
// definitions: Array(4) // meaning: "verbo transitivo" // origin: undefined // word: "buscar"
</script>
<style>
.border-bottom-dotted {
    border-bottom: 2px dashed #4a3216;
}
.meaning-content {
    border: 1.5px solid #514773;
    background: white;
}
</style>
